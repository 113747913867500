import React from "react";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";

const DetalleRequerimientoPersonalComponente = ({
  dataSolicitudMovimientoPersonal,
  aviso,
}) => {
  const formatFecha = (fecha) => {
    if (!fecha) return "";
    const date = new Date(fecha);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography variant="h5" component="div">
            Detalle de Requerimiento de Personal
          </Typography>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={12}>
              <Typography variant="body2">
                <strong>Nombre Solicitante:</strong>{" "}
                {dataSolicitudMovimientoPersonal.NombreCompletoSolicitante}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                <strong>Puesto Laboral:</strong>{" "}
                {dataSolicitudMovimientoPersonal.PuestoLaboral}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                <strong>Cantidad de Plazas:</strong>{" "}
                {dataSolicitudMovimientoPersonal.CantidadPlazas}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                <strong>Tipo Contrato:</strong>{" "}
                {dataSolicitudMovimientoPersonal.TipoContrato}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                <strong>Fecha Inicio:</strong>{" "}
                {formatFecha(
                  dataSolicitudMovimientoPersonal.FechaInicioContrato
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                <strong>Fecha Fin:</strong>{" "}
                {formatFecha(dataSolicitudMovimientoPersonal.FechaFinContrato)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                <strong>Jornada de Trabajo:</strong>{" "}
                {dataSolicitudMovimientoPersonal.TipoJornadaTrabajo}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                <strong>Horario de Trabajo:</strong>{" "}
                {dataSolicitudMovimientoPersonal.HoraEntrada} -
                {dataSolicitudMovimientoPersonal.HoraSalida}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                <strong>Personal a Cargo:</strong>{" "}
                {dataSolicitudMovimientoPersonal.PersonalACargo}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ marginTop: "10px" }}>
        <CardContent>
          <Typography variant="h5" component="div">
            Perfil de Contratación
          </Typography>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={12}>
              <Typography variant="body2">
                <strong>Nombre para Convocatoria:</strong>{" "}
                {dataSolicitudMovimientoPersonal.NombreReclutamiento}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                <strong>Descripción para Convocatoria:</strong>{" "}
                {dataSolicitudMovimientoPersonal.DescripcionReclutamiento}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                <strong>Beneficios:</strong>{" "}
                {dataSolicitudMovimientoPersonal.BeneficiosReclutamiento}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                <strong>Funciones:</strong>{" "}
                {dataSolicitudMovimientoPersonal.FuncionesReclutamiento}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                <strong>Habilidades:</strong>{" "}
                {dataSolicitudMovimientoPersonal.HabilidadesReclutamiento}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                <strong>Beneficios privados:</strong>{" "}
                {
                  dataSolicitudMovimientoPersonal.BeneficiosPrivadosReclutamiento
                }
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default DetalleRequerimientoPersonalComponente;
