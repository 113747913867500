import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import Loader from "react-loader-spinner";
import Popover from "@material-ui/core/Popover";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import AppsIcon from "@material-ui/icons/Apps";
import FolderIcon from "@material-ui/icons/Folder";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import { Chip, Backdrop, CircularProgress } from "@material-ui/core";

import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import LinearProgress from "@material-ui/core/LinearProgress";

//Appbar
import { fade } from "@material-ui/core/styles";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import PerfectScrollbar from "react-perfect-scrollbar";

//Own components
import Layout from "../../layout/containers/Layout";
import ButtonAdd from "../../helpers/components/button-add";
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { validaPermisoPaginaActual } from "../../utils/helpers";
//Componentes Ficha Clinica
// import UsuariosList from "../../avisos-objects/components/usuarios-list";
import UsuariosList from "../../avisos-objects/components/usuarios-list";
import DatosEmpleado from "../components/datos-empleado";
import DatosEmpleadoEditar from "../../perfil/components/dato-empleado-editar";
const styles = (theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "relative",
    width: "100%", //theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  popover: {
    pointerEvents: "none",
  },
  paperPopover: {
    padding: theme.spacing(1),
    background: "#000",
    color: "#fff",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  rootExpansionPanel: {
    width: "100%",
    marginTop: 30,
    marginBottom: 50,
  },
  ExpansionPanelRow: {},
  alignCenter: {
    alignItems: "center",
  },
  dowloadButton: {
    backgroundColor: "white",
    color: "#2196F3",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#f3f3f3",
      color: "#2196F3",
    },
  },
  titleGestiones: {
    display: "flex",
    flexDirection: "row",
  },
  heading: {
    marginLeft: 15,
  },
  secondNavigationModal: {
    position: "absolute",
    width: "50%",
    height: "400px",
    maxHeight: "400px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
});

const LimitedBackdrop = withStyles({
  root: {
    position: "absolute",
    zIndex: 1,
  },
})(Backdrop);

const titleColor = "#2B3C4D";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

class FichaClinica extends Component {
  state = {
    buscando: false,
    dpi: "0",
    dataEmpleado: {},
    encontrado: false,
    openAsignarUsuarios: true,
    txtTextoBusquedaUsuario: "",
    listaUsuariosModal: [],
    codigoUsuario: null,
  };

  async componentWillMount() {
    // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.FichaClinica";
    await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    // FIN DE CONSULTA DE PERMISOS
  }

  buscar = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      this.setState({
        buscando: true,
        encontrado: false,
      });

      let uri = "api/fichaClinica/empleado/" + this.state.dpi;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        console.log(request.data.data);
        if (request.data.response) {
          this.state.dataEmpleado = request.data.data[0];
          this.state.encontrado = true;
        } else {
          toast.error("No se encontró al empleado");
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ buscando: false });
    }
  };

  cargarListaUsuariosModal = async () => {
    let criterio = this.state.txtTextoBusquedaUsuario;
    const request = await API.fetchGetRequest(
      `api/empleado/buscar/${criterio}`
    );
    try {
      if (Array.isArray(request.data.usuarios)) {
        console.log("usuariosModal", request.data.usuarios);
        // setListaUsuariosModal(request.data.usuarios);
        this.setState({ listaUsuariosModal: request.data.usuarios });
      } else {
        // setListaUsuariosModal([]);
        this.setState({ listaUsuariosModal: [] });
      }
    } catch (error) {
      console.log("cargarListaTareas error:" + error);
    }
  };

  seleccionarUsuario = async (codigoUsuario, usuario) => {
    if (usuario !== "") {
      // setCodigoUsuario(codigoUsuario);
      // this.setState({ codigoUsuario: codigoUsuario });

      const fila = this.state.listaUsuariosModal.filter(
        (x) => x.Usuario === usuario
      );
      // console.log("Fila seleccionada", fila);
      if (Array.isArray(fila) && fila.length > 0) {
        // setDpi(fila[0].Usuario);
        this.setState({ dpi: fila[0].Usuario });
        this.buscar();
      }

      // setOpenAsignarUsuarios(false);
      this.setState({ openAsignarUsuarios: false });
    }
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  onHandleChangeDatosEmpleado = async (nuevoDataEmpleado) => {
    this.setState({ dataEmpleado: nuevoDataEmpleado });
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.buscar();
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Layout titulo={"Clínica ILU - Datos de Empleado"}>
        <ToastContainer></ToastContainer>
        <Backdrop className={classes.backdrop} open={this.state.buscando}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth="lg">
          <Paper id="cuadroBusqueda" className={classes.paper}>
            <LimitedBackdrop
              className={classes.backdrop}
              open={this.state.buscando}
            >
              <CircularProgress color="inherit" />
            </LimitedBackdrop>
            <div style={{ width: "100% !important" }}>
              <Grid container spacing={3} style={{ width: "100% !important" }}>
                <form
                  onKeyPress={(event) => {
                    if (event.which === 13 /* Enter */) {
                      event.preventDefault();
                    }
                  }}
                  className={classes.form}
                  noValidate
                  autoComplete="off"
                >
                  <div className={classes.search}>
                    {/* <TextField
                      id="texto-dpi"
                      className={classes.TextField}
                      label="Ingrese DPI de Empleado"
                      variant="outlined"
                      type="search"
                      autoFocus
                      onChange={this.handleChange("dpi")}
                      onKeyPress={this.handleKeyPress}
                      style={{
                        marginBottom: 10,
                        marginTop: 10,
                        marginLeft: 30,
                        width: "93%",
                      }}
                    /> */}
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#69bd4b",
                        marginBottom: 10,
                        marginTop: 10,
                        color: "white",
                        width: "93%",
                        marginLeft: 30,
                      }}
                      className={classes.buttonAdd}
                      onClick={() => {
                        this.setState({ openAsignarUsuarios: true });
                      }}
                    >
                      Buscar
                    </Button>
                  </div>
                </form>
              </Grid>
            </div>
          </Paper>
          {this.state.encontrado === true && (
            <div>
              <Paper id="resultado" className={classes.paper}>
                <LimitedBackdrop
                  className={classes.backdrop}
                  open={this.state.buscando}
                >
                  <CircularProgress color="inherit" />
                </LimitedBackdrop>
                <DatosEmpleado dataEmpleado={this.state.dataEmpleado} />
                <br />

                <br />
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#69bd4b",
                    color: "white",
                  }}
                  className={classes.buttonAdd}
                  onClick={() => {
                    // this.props.history.push(
                    //   "/FichaClinicaEmpleado/" + this.state.dataEmpleado.DPI
                    // );
                    window.open(
                      "/FichaClinicaEmpleado/" + this.state.dataEmpleado.DPI,
                      "_blank"
                    );
                  }}
                >
                  Ver Ficha Clinica
                </Button>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#69bd4b",
                    color: "white",
                  }}
                  className={classes.buttonAdd}
                  onClick={() => {
                    // this.props.history.push("/aviso/nuevo/288");
                    window.open(
                      "/clinica/nuevo/" + this.state.dataEmpleado.DPI,
                      "_blank"
                    );
                  }}
                >
                  Crear Consulta
                </Button>
                <br />

                <br />
                <DatosEmpleadoEditar
                  dataEmpleado={this.state.dataEmpleado}
                  onChangeDataEmpleado={this.onHandleChangeDatosEmpleado}
                />
              </Paper>
            </div>
          )}
        </Container>

        <Modal
          // style={{ zIndex: 2990 }}
          aria-labelledby="Asignar usuarios"
          aria-describedby="Asignar usuarios"
          open={this.state.openAsignarUsuarios}
          onClose={() => {
            // setOpenAsignarUsuarios(false);
            this.setState({ openAsignarUsuarios: false });
          }}
        >
          <div
            style={{
              top: `${50}%`,
              left: `${50}%`,
              transform: `translate(-${50}%, -${50}%)`,
            }}
            className={classes.paperUserModal}
          >
            <PerfectScrollbar>
              <form className={classes.container} noValidate autoComplete="off">
                <Typography variant="h6" gutterBottom>
                  Selecciona un usuario
                </Typography>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="txtTextoBusquedaUsuario"
                      label="Criterio de búsqueda"
                      className={classes.textField}
                      value={this.state.txtTextoBusquedaUsuario}
                      onChange={(event) => {
                        // setTxtTextoBusquedaUsuario(event.target.value);
                        this.setState({
                          txtTextoBusquedaUsuario: event.target.value,
                        });
                      }}
                      onKeyPress={(ev) => {
                        console.log("keyP: " + ev.key);
                        if (ev.key === "Enter") {
                          this.cargarListaUsuariosModal();
                          ev.preventDefault();
                        }
                      }}
                      margin="normal"
                      inputProps={{ maxLength: 512 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#217ecf",
                        color: "white",
                        width: "100%",
                      }}
                      className={classes.buttonAdd}
                      onClick={() => this.cargarListaUsuariosModal()}
                    >
                      Buscar
                    </Button>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={12} sm={12}>
                  <Grid container spacing={1}>
                    <UsuariosList
                      registros={this.state.listaUsuariosModal}
                      seleccionarUsuario={this.seleccionarUsuario}
                    />
                  </Grid>
                </Grid>
              </form>
            </PerfectScrollbar>
          </div>
        </Modal>
      </Layout>
    );
  }
}

const FichaClinicaContainer = withStyles(styles)(FichaClinica);
export default withRouter(FichaClinicaContainer);
