import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Paper,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Tab,
  Tabs,
  TabScrollButton,
  Box,
  Link,
  Breadcrumbs,
  Switch,
  FormControlLabel,
} from "@material-ui/core";

import PropTypes from "prop-types";

import BackdropModal from "@material-ui/core/Backdrop";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  Edit,
  Delete,
  Add,
  GroupWork,
  ArrowBack,
  Work,
  GroupAdd,
  SwapHoriz,
  SupervisedUserCircle,
  MonetizationOn,
  Ballot,
} from "@material-ui/icons";

import { green } from "@material-ui/core/colors";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Editing,
  Scrolling,
} from "devextreme-react/data-grid";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import {
  validaPermisoPaginaActual,
  verificaPermisoPaginaActual,
} from "../../utils/helpers";
import { toast } from "react-toastify";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";
import EmpleadoPlazaLaboral from "./empleado-plaza-componente";
import MovimientoDePersonal from "./movimiento-personal-componente";
import Gestores from "./gestores-componente";
import PlazaLaboral from "./plaza-laboral-componente";
import Organigrama from "./organigrama-componente";
import Bitacora from "./bitacora-plaza-laboral-componente";
import AnalisisSalarial from "../containers/analisis-salarial-container";
import RequerimientoPersonal from "./requerimiento-personal-componente";

const width_proportion = "100%";
const titleColor = "#2B3C4D";

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const MyTabScrollButton = withStyles((theme) => ({
  root: {
    width: 28,
    overflow: "hidden",
    transition: "width 0.5s",
    "&.Mui-disabled": {
      width: 0,
    },
  },
}))(TabScrollButton);

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing(1),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    minWidth: 480,

    marginBottom: 10,
    marginTop: 10,
    width: "100%",
    marginLeft: 30,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing(1),
    overflowX: "auto",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  rootOrg: {
    width: "100%",
    marginTop: theme.spacing(1),
    minHeight: 800,
    // maxWidth:600,
    // overflowX: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
  },
  paperModal: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "scroll",
  },
  tabLabel: {
    width: "100px", // Ancho fijo
    whiteSpace: "normal", // Permite el ajuste de línea
    overflow: "hidden", // Oculta el desbordamiento
    textAlign: "center", // Centra el texto
  },
}));

const Organizacion = (props) => {
  const classes = useStyles();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: fieldsErrors },
    register,
  } = useForm();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];
  const [codigoTemporadaActual, setCodigoTemporadaActual] = useState(4);
  const [tab, setTab] = useState(0);
  const [codigoNivelOrganizacion, setCodigoNivelOrganizacion] = useState(1);
  const [maestroNivelOrganizacion, setMaestroNivelOrganizacion] = useState([]);
  const [
    maestroNivelPuestoLaboralOrganigrama,
    setMaestroNivelPuestoLaboralOrganigrama,
  ] = useState([]);
  const [textoNivelOrganizacion, setTextoNivelOrganizacion] = useState("");
  const [registroRaiz, setRegistroRaiz] = useState({});
  const [registroActual, setRegistroActual] = useState({});
  const [nombre, setNombre] = useState("");

  const [codigoPlazaLaboral, setCodigoPlazaLaboral] = useState(0);
  const [grado, setGrado] = useState(0);
  const [organigrama, setOrganigrama] = useState(null);
  const [organigramaNodos, setOrganigramaNodos] = useState([]);
  const [mostrarOrganigramaCompleto, setMostrarOrganigramaCompleto] =
    useState(false);

  const [userName, setUserName] = useState("");
  const [open, setOpen] = useState(false);
  const [openEPL, setOpenEPL] = useState(false);
  const [cargandoMaestros, setCargandoMaestros] = useState(false);
  const [organizacionHijos, setOrganizacionHijos] = useState([]);
  const [permisosExploracion, setPermisosExploracion] = useState([]);

  const [codigoUnidadOrganizacion, setCodigoUnidadOrganizacion] = useState(0);
  const [disponibilidad, setDisponibilidad] = useState(0);
  const [descripcion, setDescripcion] = useState("");

  const [valueAutoCompletePuestoLaboral, setValueAutoCompletePuestoLaboral] =
    useState(null);

  const [
    valueAutoCompleteNivelPuestoLaboral,
    setValueAutoCompleteNivelPuestoLaboral,
  ] = useState(null);
  const [
    codigoNivelPuestoLaboralOrganigrama,
    setCodigoNivelPuestoLaboralOrganigrama,
  ] = useState(null);
  const [positionDisableSorting, setPositionDisableSorting] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);
  const [codigoGerenciaActual, setCodigoGerenciaActual] = useState(0);

  const [buscando, setBackDrop] = useState(false);

  const [listaNavegacion, setListaNavegacion] = useState([]);

  const [temporadaActual, setTemporadaActual] = useState(null);

  const [maestroSubNivelPuestoLaboral, setMaestroSubNivelPuestoLaboral] =
    useState([]);

  const [bitacoraUltimoCambio, setBitacoraUltimoCambio] = useState(null);

  //permisos
  const [visualizarArea, setVisualizarArea] = useState(false);
  const [visualizarGerencia, setVisualizarGerencia] = useState(false);
  const [visualizarCompleto, setVisualizarCompleto] = useState(false);
  const [editarCompleto, setEditarCompleto] = useState(false);
  const [permisoPlazaLaboral, setPermisoPlazaLaboral] = useState(false);
  const [permisoPersonal, setPermisoPersonal] = useState(false);
  const [permisoMovimientos, setPermisoMovimientos] = useState(false);
  const [permisoRequerimientos, setPermisoRequerimientos] = useState(false);
  const [permisoAnalisisSalarial, setPermisoAnalisisSalarial] = useState(false);
  const [permisoGestores, setPermisoGestores] = useState(false);
  const [permisoFlujoAutorizacion, setPermisoFlujoAutorizacion] =
    useState(false);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const handleOpenModal = () => {
    setOpen(true);
    setCodigoUnidadOrganizacion(0);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setCodigoUnidadOrganizacion(0);
  };

  const handleOpenModalEmpleadoPlazaLaboral = () => {
    setOpenEPL(true);
  };

  const handleCloseModalEmpleadoPlazaLaboral = () => {
    setOpenEPL(false);
  };

  useEffect(() => {
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "GESPILU";
      const llavePagina = "GESPILU.Organización.Acceso";
      await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);

      const llaveVisualizarArea = "GESPILU.Organigramas.Visualizar_Area";
      let verArea = await verificaPermisoPaginaActual(
        llaveAgrupacion,
        llaveVisualizarArea
      );
      setVisualizarArea(verArea);

      const llaveVisualizarGerencia =
        "GESPILU.Organigramas.Visualizar_Gerencia";
      let verGerencia = await verificaPermisoPaginaActual(
        llaveAgrupacion,
        llaveVisualizarGerencia
      );
      setVisualizarGerencia(verGerencia);

      const llaveVisualizarCompleto =
        "GESPILU.Organigramas.Visualizar_Completo";
      let verCompleto = await verificaPermisoPaginaActual(
        llaveAgrupacion,
        llaveVisualizarCompleto
      );
      setVisualizarCompleto(verCompleto);

      const llaveEditarCompleto = "GESPILU.Organigramas.Editar_Completo";
      let editarCompleto = await verificaPermisoPaginaActual(
        llaveAgrupacion,
        llaveEditarCompleto
      );
      setEditarCompleto(editarCompleto);

      //permisos pestañas
      const llaveVisualizarPlazas = "GESPILU.Plazas.Visualizar_Completo";
      let verPlaza = await verificaPermisoPaginaActual(
        llaveAgrupacion,
        llaveVisualizarPlazas
      );
      setPermisoPlazaLaboral(verPlaza);

      const llaveVisualizarEmpleados = "GESPILU.Personal.Visualizar_Completo";
      let verEmpleados = await verificaPermisoPaginaActual(
        llaveAgrupacion,
        llaveVisualizarEmpleados
      );
      setPermisoPersonal(verEmpleados);

      const llaveVisualizarMovimientos =
        "GESPILU.MovimientosPersonal.Visualizar_Completo";
      let verMovimientos = await verificaPermisoPaginaActual(
        llaveAgrupacion,
        llaveVisualizarMovimientos
      );
      setPermisoMovimientos(verMovimientos);

      const llaveVisualizarRequerimientos =
        "GESPILU.RequerimientosPersonal.Visualizar_Completo";
      let verRequerimientos = await verificaPermisoPaginaActual(
        llaveAgrupacion,
        llaveVisualizarRequerimientos
      );
      setPermisoRequerimientos(verRequerimientos);

      const llaveVisualizarGestores = "GESPILU.Gestores.Visualizar_Area";
      let verGestores = await verificaPermisoPaginaActual(
        llaveAgrupacion,
        llaveVisualizarGestores
      );
      setPermisoGestores(verGestores);

      const llaveVisualizarAnalisisSalarial =
        "GESPILU.AnalisisSalarial.Editar_Completo";
      let verAnalisisSalarial = await verificaPermisoPaginaActual(
        llaveAgrupacion,
        llaveVisualizarAnalisisSalarial
      );
      setPermisoAnalisisSalarial(verAnalisisSalarial);

      const llaveVisualizarFlujoAutorizacion =
        "GESPILU.FlujoAutorizacion.Editar_Completo";
      let verFlujoAutorizacion = await verificaPermisoPaginaActual(
        llaveAgrupacion,
        llaveVisualizarFlujoAutorizacion
      );
      setPermisoFlujoAutorizacion(verFlujoAutorizacion);

      // FIN DE CONSULTA DE PERMISOS

      handleAbrirBackDrop();

      //   await cargarMaestroActividadPuestoLaboral();
      //   await cargarMaestroActividadNivelPuestoLaboral();

      await cargarMaestroNivelOrganizacion();
      await cargarDatoRaizGeneral();
      await cargaTemporadaActual();
      await cargarMaestroSubNivelPuestoLaboral();
      await cargaPermisosExploracion();
      await cargarMaestroNivelPuestoLaboralOrganigrama();

      handleCerrarBackDrop();
    };

    cargar();
  }, []);

  useEffect(() => {
    const cargar = async () => {
      if (registroActual !== undefined || registroActual !== null) {
        handleAbrirBackDrop();
        await cargaOrganizacionHijos();
        await cargaOrganigrama();

        await cargarBitacoraUltimoCambio();
        if (
          registroActual.CodigoNivelOrganizacion === 2 ||
          registroActual.CodigoNivelOrganizacion === 1
        ) {
          setCodigoGerenciaActual(registroActual.CodigoUnidadOrganizacion);
        }

        handleCerrarBackDrop();
      }
    };

    cargar();
  }, [registroActual]);

  useEffect(() => {
    const cargar = async () => {
      if (registroActual !== undefined || registroActual !== null) {
        handleAbrirBackDrop();

        await cargaOrganigrama();

        handleCerrarBackDrop();
      }
    };

    cargar();
  }, [mostrarOrganigramaCompleto]);

  useEffect(() => {
    let data = maestroNivelOrganizacion;
    if (data.length > 0) {
      var nivelActual = data.find(
        (f) => f.CodigoNivelOrganizacion === codigoNivelOrganizacion
      );

      if (nivelActual !== undefined || nivelActual !== null) {
        setTextoNivelOrganizacion(nivelActual.NivelOrganizacion);
      }
    }
  }, [codigoNivelOrganizacion]);

  const cargarBitacoraUltimoCambio = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      if (registroActual.CodigoUnidadOrganizacion === undefined) {
        // console.log("registroActual es null");
        return;
      }

      let uri =
        "api/PlazaLaboral/Bitacora/UltimoCAmbio/" +
        registroActual.CodigoUnidadOrganizacion;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        // setCodigoGestion(request.data.ficha[0].Gestion);
        return false;
      } else {
        setBitacoraUltimoCambio(request.data.ficha);
        // if (request.data.ficha.length > 0) {
        //   setCodigoGestion(request.data.ficha[0].Gestion);
        // }
      }
    } catch (e) {
      console.log(e);
      // setCodigoGestion(0);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroNivelOrganizacion = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/NivelOrganizacion/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroNivelOrganizacion(request.data.ficha);
        let data = request.data.ficha;
        if (data.length > 0) {
          var nivelActual = data.find(
            (f) => f.CodigoNivelOrganizacion === codigoNivelOrganizacion
          );

          if (nivelActual !== undefined || nivelActual !== null) {
            setTextoNivelOrganizacion(nivelActual.NivelOrganizacion);
          }
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroNivelPuestoLaboralOrganigrama = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/NivelPuestoLaboral/ParaOrganigrama";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroNivelPuestoLaboralOrganigrama(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };
  const cargarMaestroSubNivelPuestoLaboral = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/SubNivelPuestoLaboral/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroSubNivelPuestoLaboral(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };
  const cargaTemporadaActual = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/Temporada/Activo";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setTemporadaActual(request.data.ficha);
        setCodigoTemporadaActual(request.data.ficha.CodigoTemporada);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarDatoRaizGeneral = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/Organizacion/Nivel/1";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setRegistroRaiz(request.data.ficha[0]);
        setRegistroActual(request.data.ficha[0]);
        setListaNavegacion(request.data.ficha);
        console.log(request.data.ficha[0]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargaOrganizacionHijos = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      if (registroActual.CodigoUnidadOrganizacion === undefined) {
        // console.log("CodigoUnidadOrganizacion es null");
        return;
      }
      let uri =
        "api/Organizacion/Hijos/" + registroActual.CodigoUnidadOrganizacion;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setOrganizacionHijos(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargaPermisosExploracion = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/Organizacion/Permisos/" + session["userName"];

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setPermisosExploracion(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargaOrganigrama = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      if (registroActual.CodigoUnidadOrganizacion === undefined) {
        // console.log("registroActual es null");
        return;
      }

      let uri =
        "api/Organizacion/Arbol/" +
        registroActual.CodigoUnidadOrganizacion +
        "/" +
        mostrarOrganigramaCompleto +
        "/" +
        codigoNivelPuestoLaboralOrganigrama;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setOrganigrama(request.data.ficha);
        setOrganigramaNodos(request.data.lista);
        console.log(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const retorno = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/Organizacion/Padre/" + registroActual.CodigoUnidadOrganizacion;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setRegistroActual(request.data.ficha[0]);
        setListaNavegacion((oldArray) => oldArray.slice(0, -1));
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const guardarDato = async () => {
    const data = {
      CodigoUnidadOrganizacion: codigoUnidadOrganizacion,
      Nombre: nombre,
      Activo: true,
      Eliminado: false,
      UsuarioInserto: userName,
      FechaInserto: "",
      UsuarioModifico: "",
      FechaModifico: "",
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;
        if (data.CodigoUnidadOrganizacion > 0) {
          request = await API.post("api/Organizacion/", data);
        } else {
          request = await API.post(
            "api/Organizacion/" + registroActual.CodigoUnidadOrganizacion,
            data
          );
        }

        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          toast.success("Se guardó exitosamente");

          await cargaOrganizacionHijos();

          setDescripcion("");
          setNombre("");
          setCodigoUnidadOrganizacion(0);
          await cargaOrganigrama();

          setValueAutoCompletePuestoLaboral(null);
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
      handleCloseModal();
    }
  };

  const crearPlazaLaboral = async () => {
    const data = {
      CodigoPlazaLaboral: codigoPlazaLaboral,
      CodigoUnidadOrganizacion: codigoUnidadOrganizacion,
      CodigoPuestoLaboral: codigoUnidadOrganizacion,
      Disponibilidad: disponibilidad,
      Grado: grado,
      Activo: true,
      Eliminado: false,
      UsuarioInserto: userName,
      FechaInserto: "",
      UsuarioModifico: "",
      FechaModifico: "",
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post("api/PlazaLaboral/", data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          toast.success("Se guardó exitosamente");

          await cargaOrganizacionHijos();

          setDescripcion("");
          setCodigoUnidadOrganizacion(0);
          setCodigoUnidadOrganizacion(0);
          setGrado(0);

          setValueAutoCompletePuestoLaboral(null);
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
      handleCloseModal();
    }
  };

  const cuandoHayError = async (data) => {
    toast.info(
      "Existen algunos campos con error, por favor valida el formulario",
      {
        position: "top-right",
        autoClose: 3000, // Esta en milisegundos 3 segundos
      }
    );
  };

  const onExporting = async (e) => {};

  const [dialogoAbierto, abrirDialogo] = useState(false);
  const [dialogoAbiertoEditar, abrirDialogoEditar] = useState(false);
  const handleAbrirDialogoEliminar = (id) => {
    abrirDialogo(true);

    setCodigoUnidadOrganizacion(id);
  };

  const handleCerrarDialogo = () => {
    abrirDialogo(false);
  };

  const handleAbrirDialogoEditar = (id) => {
    abrirDialogoEditar(true);

    setCodigoUnidadOrganizacion(id);
  };

  const handleAbrirDialogoEmpleadoPlazaLaboral = (fila) => {
    handleOpenModalEmpleadoPlazaLaboral();

    setCodigoPlazaLaboral(fila.CodigoPlazaLaboral);
  };

  const handleCerrarDialogoEditar = () => {
    abrirDialogoEditar(false);
  };

  const quitarProyecto = async (pId) => {
    handleCerrarDialogo();
    handleAbrirBackDrop();

    const proyectosExcluidos = organizacionHijos.filter(
      (elemento) => elemento.CodigoPuestoLaboral !== pId
    );

    console.log("Quitar" + proyectosExcluidos);

    try {
      let uri = "api/Organizacion/Quitar/" + pId;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        toast.error(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      } else {
        toast.success("Se quitó el registro con éxito", {
          position: "bottom-right",
          autoClose: 3000, // Esta en milisegundos 3 segundos
        });

        await cargaOrganizacionHijos();

        setCodigoUnidadOrganizacion(0);
        setCodigoUnidadOrganizacion(0);

        setValueAutoCompletePuestoLaboral(null);

        await cargaOrganigrama();
      }
    } catch (error) {
      console.log("Error en  eliminación de proyectos" + error);
    }

    handleCerrarBackDrop();

    //alert("Quitar recurso del proyecto" + codigoRecurso);
  };

  const editarProyecto = async (pId) => {
    handleCerrarDialogoEditar();
    handleAbrirBackDrop();

    const proyectosExcluidos = organizacionHijos.filter(
      (elemento) => elemento.CodigoUnidadOrganizacion === pId
    );

    console.log("Editar el proyecto" + proyectosExcluidos[0]);

    try {
      handleOpenModal();
      setCodigoUnidadOrganizacion(pId);
      setNombre(proyectosExcluidos[0].UnidadOrganizacion);
    } catch (error) {
      console.log("Error en  edición de proyectos" + error);
    }

    handleCerrarBackDrop();

    //alert("Quitar recurso del proyecto" + codigoRecurso);
  };

  const cellOpciones = (rowInfo) => {
    return (
      <>
        {/* <Tooltip
          title="Ver detalle"
          style={{ marginRight: 5, cursor: "pointer" }}
        >
          <GroupWork
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() => setRegistroActual(rowInfo.data)}
          />
        </Tooltip> */}
        {/* <Tooltip
          title="Asignar Puesto Laboral"
          style={{ marginRight: 5, cursor: "pointer" }}
        >
          <Work
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() => handleAbrirDialogoPuestoLaboral(rowInfo.data)}
          />
        </Tooltip> */}
        {/* <Tooltip
          title="Asignar Empleados"
          style={{ marginRight: 5, cursor: "pointer" }}
        >
          <GroupAdd
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() => handleAbrirDialogoEmpleadoPlazaLaboral(rowInfo.data)}
          />
        </Tooltip> */}
        <Tooltip title="Editar" style={{ marginRight: 10, cursor: "pointer" }}>
          <Edit
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() =>
              handleAbrirDialogoEditar(rowInfo.data.CodigoUnidadOrganizacion)
            }
          />
        </Tooltip>
        <Tooltip
          title="Eliminar"
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          <Delete
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() =>
              handleAbrirDialogoEliminar(rowInfo.data.CodigoUnidadOrganizacion)
            }
          />
        </Tooltip>
      </>
    );
  };

  const handleOnChangePuestoLaboral = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoUnidadOrganizacion(newValue.CodigoPuestoLaboral);
      setValueAutoCompletePuestoLaboral(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangeNivelPuestoLaboral = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoNivelPuestoLaboralOrganigrama(newValue.CodigoNivelPuestoLaboral);
      setValueAutoCompleteNivelPuestoLaboral(newValue);
    }
    handleCerrarBackDrop();
  };

  const cellTemplateUnidadOrganizacion = (rowInfo) => {
    let tienePermisos =
      permisosExploracion.includes(rowInfo.data.CodigoUnidadOrganizacion) ||
      visualizarCompleto;

    return (
      <>
        {tienePermisos ? (
          <Tooltip
            title={`Ir a: ${rowInfo.data.UnidadOrganizacion}`}
            style={{ marginRight: 10, cursor: "pointer" }}
          >
            <Link
              onClick={(e) => {
                if (tienePermisos) {
                  setRegistroActual(rowInfo.data);
                  setListaNavegacion((oldArray) => [...oldArray, rowInfo.data]);
                  e.preventDefault();
                } else {
                  toast.error("No tiene permisos");
                  e.preventDefault();
                }
              }}
            >
              {rowInfo.data.UnidadOrganizacion}
            </Link>
          </Tooltip>
        ) : (
          <p>{rowInfo.data.UnidadOrganizacion}</p>
        )}
      </>
    );
  };

  const handleMigasDePan = (nodo) => {
    try {
      setRegistroActual(nodo);
      let lista = [];
      listaNavegacion.forEach(function (n, index, object) {
        if (n.CodigoUnidadOrganizacion === nodo.CodigoUnidadOrganizacion) {
          lista = object.slice(0, index + 1);
        }
      });

      setListaNavegacion(lista);
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    }
  };

  const handleChangeSwitchOrganigrama = (event) => {
    setMostrarOrganigramaCompleto(event.target.checked);
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* Dialogos */}
      <Dialog
        id="dialogo-eliminar"
        open={dialogoAbierto}
        onClose={handleCerrarDialogo}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas eliminar este registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrarDialogo} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => quitarProyecto(codigoUnidadOrganizacion)}
            color="primary"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogoAbiertoEditar}
        onClose={handleCerrarDialogoEditar}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edición</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas editar este registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrarDialogoEditar} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => editarProyecto(codigoUnidadOrganizacion)}
            color="primary"
          >
            Editar
          </Button>
        </DialogActions>
      </Dialog>
      {/* Modal CRUD unidad organización */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={BackdropModal}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paperModal}>
            <h2 id="simple-modal-title">Crear unidad de organización</h2>
            <Grid container spacing={3}>
              <form
                autoComplete="off"
                onSubmit={handleSubmit(guardarDato, cuandoHayError)}
              >
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="nombre"
                      render={({ _nombre }) => (
                        <TextField
                          id="nombre"
                          label="Nombre de Unidad"
                          name="unidad"
                          value={nombre}
                          onChange={(e) => setNombre(e.target.value)}
                          variant="outlined"
                          helperText={
                            fieldsErrors.nombre
                              ? fieldsErrors.nombre.message
                              : ""
                          }
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <Button
                    width="100%"
                    variant="contained"
                    style={{
                      backgroundColor: "#69bd4b",
                      marginBottom: 10,
                      marginTop: 10,
                      color: "white",
                      width: "93%",
                      marginLeft: 30,
                    }}
                    type="submit"
                  >
                    &nbsp;Guardar Cambios
                  </Button>
                </Grid>
              </form>
            </Grid>
          </div>
        </Fade>
      </Modal>

      <AppBar
        position="sticky"
        className={classes.appBar}
        style={{ backgroundColor: titleColor }}
      >
        <Toolbar>
          {registroActual.CodigoNivelOrganizacion > 1 && (
            <Tooltip
              title={"Regresar al nivel anterior"}
              aria-label="Regresar al nivel anterior"
            >
              <IconButton
                aria-label="Regresar al nivel anterior"
                style={{ color: "white" }}
                onClick={retorno}
              >
                <ArrowBack />
              </IconButton>
            </Tooltip>
          )}
          <Typography
            variant="h6"
            noWrap
            style={{ display: "flex", flexGrow: 1 }}
          >
            {registroActual.UnidadOrganizacion}
          </Typography>

          {editarCompleto && (
            <Tooltip title={"Crear nuevo ítem"} aria-label="Crear nuevo ítem">
              <IconButton
                aria-label="Crear nuevo ítem"
                style={{ color: "white" }}
                onClick={handleOpenModal}
              >
                <Add />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
      </AppBar>
      <Paper className={classes.rootTabla} pl={10}>
        {temporadaActual !== undefined && temporadaActual !== null && (
          <Typography variant="body1" pl={10}>
            Temporada Actual: {temporadaActual.TipoTemporada} -{" "}
            {temporadaActual.Anio}
          </Typography>
        )}
        <br />
        <Breadcrumbs aria-label="breadcrumb" pl={10}>
          {listaNavegacion.length > 0 &&
            listaNavegacion.map((nodo) => (
              <Link
                key={nodo.CodigoUnidadOrganizacion}
                color="inherit"
                href="#"
                onClick={() => handleMigasDePan(nodo)}
              >
                {nodo.UnidadOrganizacion}
              </Link>
            ))}
        </Breadcrumbs>
        {permisosExploracion && (
          <DataGrid
            dataSource={organizacionHijos}
            //   defaultColumns={this.props.columns}
            showBorders={true}
            rowAlternationEnabled={true}
            //ref={(ref) => (dataGrid = ref)}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            onExporting={onExporting}
            className={classes.table}
          >
            <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
            <HeaderFilter visible={showHeaderFilter} />
            <GroupPanel visible={false} />
            <Grouping autoExpandAll={autoExpandAll} />
            <SearchPanel visible={false} width={240} placeholder="Search..." />
            {/* <Selection mode="multiple" /> */}
            <Editing
              mode="row"
              useIcons={true}
              allowUpdating={false}
              allowDeleting={false}
            />
            <Column
              caption="Opciones"
              allowFiltering={false}
              allowSorting={false}
              cellRender={cellOpciones}
              visible={editarCompleto}
            />
            <Column
              dataField="CodigoUnidadOrganizacion"
              caption="Código DB"
              dataType="number"
              visible={false}
            />
            <Column
              dataField="CodigoNivelOrganizacion"
              caption="Código Nivel Organización"
              dataType="number"
              visible={false}
            />
            <Column dataField="NivelOrganizacion" caption="Nivel" />
            <Column
              dataField="UnidadOrganizacion"
              caption="Nombre"
              cellRender={cellTemplateUnidadOrganizacion}
            />
            <Column dataField="Activo" caption="Activo" dataType="boolean" />

            <ColumnFixing enabled={true} />
            <Sorting mode="multiple" />
            <Paging defaultPageSize={20} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[10, 20, 50]}
              showInfo={true}
            />
            <Export enabled={true} allowExportSelectedData={true} />
            <Scrolling columnRenderingMode="virtual" />
          </DataGrid>
        )}
      </Paper>
      {/* <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12}> */}
      <Paper className={classes.rootTabla}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, val) => setTab(val)}
          variant="scrollable"
          scrollButtons="on"
          aria-label="scrollable force tabs example"
          ScrollButtonComponent={MyTabScrollButton}
        >
          <Tab
            icon={<GroupWork />}
            label="Organigrama"
            {...a11yProps(0)}
            style={{ textTransform: "none" }}
            value={0}
          />
          <Tab
            icon={<Work />}
            label={<span className={classes.tabLabel}>Plazas Laborales</span>}
            {...a11yProps(1)}
            style={{ textTransform: "none" }}
            value={1}
            disabled={!permisoPlazaLaboral}
          />
          <Tab
            icon={<SupervisedUserCircle />}
            label="Personal"
            {...a11yProps(2)}
            style={{ textTransform: "none" }}
            value={2}
            disabled={!permisoPersonal}
          />
          <Tab
            icon={<SwapHoriz />}
            label={
              <span className={classes.tabLabel}>Movimiento de Personal</span>
            }
            {...a11yProps(3)}
            style={{ textTransform: "none" }}
            value={3}
            disabled={!permisoMovimientos}
          />
          <Tab
            icon={<GroupAdd />}
            // label="Requerimiento de Personal"
            label={
              <span className={classes.tabLabel}>
                Requerimiento de Personal
              </span>
            }
            {...a11yProps(4)}
            style={{
              textTransform: "none",
            }}
            value={4}
            disabled={!permisoRequerimientos}
          />
          <Tab
            icon={<MonetizationOn />}
            label={<span className={classes.tabLabel}>Análisis Salarial</span>}
            {...a11yProps(5)}
            style={{ textTransform: "none" }}
            value={5}
            disabled={!permisoAnalisisSalarial}
          />
          <Tab
            icon={<SupervisedUserCircle />}
            label="Gestores"
            {...a11yProps(6)}
            style={{ textTransform: "none" }}
            value={6}
            disabled={!permisoGestores}
          />
        </Tabs>
        <TabPanel id="organigrama" value={tab} index={0}>
          <Box className={classes.box} pl={0} pt={0}>
            <Paper className={classes.rootOrg} spacing={3}>
              <FormControlLabel
                control={
                  <Switch
                    checked={mostrarOrganigramaCompleto}
                    onChange={handleChangeSwitchOrganigrama}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Mostrar organigrama completo"
              />

              <Grid container spacing={3}>
                <form
                  autoComplete="off"
                  onSubmit={handleSubmit(cargaOrganigrama, cuandoHayError)}
                >
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <FormControl className={classes.formControlSelects}>
                      <Controller
                        name="puestoLaboral"
                        required
                        render={({ PuestoLaboral }) => (
                          <Autocomplete
                            id="puestoLaboral"
                            options={maestroNivelPuestoLaboralOrganigrama}
                            getOptionLabel={(option) =>
                              option.NivelPuestoLaboral
                            }
                            value={valueAutoCompleteNivelPuestoLaboral}
                            onChange={(event, newValue) =>
                              handleOnChangeNivelPuestoLaboral(event, newValue)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Nivel Puesto Laboral"
                                variant="outlined"
                              />
                            )}
                          />
                        )}
                        control={control}
                      />
                    </FormControl>
                    <Button
                      width="100%"
                      variant="contained"
                      style={{
                        // backgroundColor: "#69bd4b",
                        marginBottom: 10,
                        marginTop: 10,
                        // color: "white",
                        width: "93%",
                        marginLeft: 30,
                      }}
                      type="submit"
                      disabled={mostrarOrganigramaCompleto}
                    >
                      &nbsp;Mostrar Nivel
                    </Button>
                  </Grid>
                </form>
              </Grid>

              <Organigrama
                nodos={organigramaNodos}
                maestroSubNivelPuestoLaboral={maestroSubNivelPuestoLaboral}
                handleAbrirBackDrop={handleAbrirBackDrop}
                handleCerrarBackDrop={handleCerrarBackDrop}
                bitacoraUltimoCambio={bitacoraUltimoCambio}
              />
            </Paper>
            <Paper className={classes.rootTabla}>
              <Bitacora
                codigoUnidadOrganizacion={
                  registroActual.CodigoUnidadOrganizacion
                }
              />
            </Paper>

            {/* {organigrama !== undefined && organigrama !== null && (
                <OrganizationChart
                  datasource={organigrama}
                  pan={true}
                  zoom={true}
                  chartClass="myChart"
                  NodeTemplate={MyNode}
                />
              )} */}
          </Box>
        </TabPanel>
        <TabPanel id="plazas" value={tab} index={1}>
          <Box className={classes.box} pl={0} pt={0}>
            <PlazaLaboral
              codigoGerencia={codigoGerenciaActual}
              codigoUnidadOrganizacion={registroActual.CodigoUnidadOrganizacion}
              cargaOrganigrama={cargaOrganigrama}
            />
          </Box>
        </TabPanel>
        <TabPanel id="personal" value={tab} index={2}>
          <Box className={classes.box} pl={0} pt={0}>
            <EmpleadoPlazaLaboral
              codigoUnidadOrganizacion={registroActual.CodigoUnidadOrganizacion}
              codigoTemporada={codigoTemporadaActual}
            />
          </Box>
        </TabPanel>
        <TabPanel id="cambioTemporada" value={tab} index={3}>
          <Box className={classes.box} pl={0} pt={0}>
            <MovimientoDePersonal
              codigoUnidadOrganizacion={registroActual.CodigoUnidadOrganizacion}
              // codigoGerencia={codigoGerenciaActual}
            />
          </Box>
        </TabPanel>
        <TabPanel id="requerimientoPersonal" value={tab} index={4}>
          <Box className={classes.box} pl={0} pt={0}>
            <RequerimientoPersonal
              codigoUnidadOrganizacion={registroActual.CodigoUnidadOrganizacion}
              // codigoGerencia={codigoGerenciaActual}
            />
          </Box>
        </TabPanel>
        <TabPanel id="analisis-salarial" value={tab} index={5}>
          <Box className={classes.box} pl={0} pt={0}>
            <AnalisisSalarial
              codigoUnidadOrganizacion={registroActual.CodigoUnidadOrganizacion}
            />
          </Box>
        </TabPanel>
        <TabPanel id="gestores" value={tab} index={6}>
          <Box className={classes.box} pl={0} pt={0}>
            <Gestores
              codigoUnidadOrganizacion={registroActual.CodigoUnidadOrganizacion}
            />
          </Box>
        </TabPanel>
      </Paper>
      {/* </Grid>
      </Grid> */}
    </React.Fragment>
  );
};

export default Organizacion;
