import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Typography,
  makeStyles,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
} from "@material-ui/core";
import PropTypes from "prop-types";

import { isSignedIn } from "../../utils/auth";
import API from "../../utils/api";

import DetalleDocumentosComponente from "./detalle-documentos-componente";
import VisorListadoArchivos from "./visor-documentos-personales";
import { CloudUpload } from "@material-ui/icons";

const useStyles = makeStyles({
  recurso: {
    padding: "0.5em",
    "&:hover": {
      background: "#DEDEDE",
    },
  },
  recursoActions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  root: {
    flexGrow: 1,
    marginTop: 10,
  },
});

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const EmpleadoDocumentosComponente = ({ DPI }) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(1);
  const [archivos, setArchivos] = useState([]);
  const [archivosBasicos, setArchivosBasicos] = useState([]);
  const [dialogUpload, setDialogUpload] = useState(false);
  const [dialogLink, setDialogLink] = useState(false);
  const [dialogListaArchivos, setDialogListaArchivos] = useState(false);
  const [upload, setUpload] = useState(null);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [contenedorURL, setContenedorURL] = useState(null);

  const obtenerDocumentoEmpleado = async () => {
    try {
      const session = await isSignedIn();
      if (!session.response) {
        window.location.href = "/login";
        return;
      } else {
        // setUserName(session["userName"]);
      }

      let uri = "api/EmpleadoDocumento/" + DPI;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        return false;
      } else {
        if (request.data.ficha) {
          setArchivos(request.data.ficha);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const obteneMaestroDocumento = async () => {
    try {
      const session = await isSignedIn();
      if (!session.response) {
        window.location.href = "/login";
        return;
      } else {
        // setUserName(session["userName"]);
      }

      let uri = "api/Documentos/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        return false;
      } else {
        if (request.data.ficha) {
          setArchivosBasicos(request.data.ficha);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const obtenerContenedor = async () => {
    try {
      const session = await isSignedIn();
      if (!session.response) {
        window.location.href = "/login";
        return;
      } else {
        // setUserName(session["userName"]);
      }
      let uri = "api/EmpleadoDocumento/Contenedor/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        return false;
      } else {
        if (request.data.ficha) {
          setContenedorURL(request.data.ficha);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await obtenerDocumentoEmpleado();
      await obteneMaestroDocumento();
    };
    fetchData();
  }, [DPI]);

  useEffect(() => {
    obtenerContenedor();
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const openListaArchivos = (item, index) => {
    // setSelected({ ...archivosBasicos[index] });
    setSelected(item);
    setDialogListaArchivos(true);
  };

  const closeUpload = () => {
    if (!loading) {
      setUpload(null);
      setDialogUpload(false);
    }
  };
  const openUpload = (item, index) => {
    // console.log('index' + index);
    // console.log(item);
    setSelected(item);
    setDialogUpload(true);
  };
  const showResource = (url) => {
    window.open(url, "_blank", "fullscreen=yes");
    return false;
  };
  const deleteArchivo = async (recurso) => {
    // if (window.confirm('¿Estas seguro de borrar el archivo?')) {
    //   // const recurso = archivos[index];
    //   try {
    //     setLoading(true);
    //     await http.post(`/Files/Delete?filename=${recurso.filename}`);
    //     await http.post('/Recursos/Update', { ...recurso, status: 0 });
    //     getArchivos();
    //   } catch (error) {
    //     setMessage({
    //       state: true,
    //       variant: 'error',
    //       text: 'Ha ocurrido un error',
    //     });
    //     console.log(error);
    //   } finally {
    //     setLoading(false);
    //   }
    //   // await http.post(`/Files/Delete?filename=${recurso.filename}`);
    //   // await http.post('/Recursos/Update', { ...recurso, status: 0 });
    //   // getArchivos();
    // }
  };

  return (
    <Paper className={classes.root}>
      <Tabs value={activeTab} onChange={handleTabChange} centered={true}>
        <Tab label="Personales" value={1} {...a11yProps(1)} />
        <Tab label="Academicos" value={2} {...a11yProps(2)} />
        <Tab label="Laborales" value={3} {...a11yProps(3)} />
      </Tabs>
      <TabPanel value={activeTab} index={1}>
        {archivosBasicos.length > 0 && (
          <DetalleDocumentosComponente
            archivos={archivos}
            archivosBasicos={archivosBasicos.filter(
              (w) => w.CodigoTipoDocumento === activeTab
            )}
            classes={classes}
            openUpload={openUpload}
            openListaArchivos={openListaArchivos}
          />
        )}
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        <DetalleDocumentosComponente
          archivos={archivos}
          archivosBasicos={archivosBasicos.filter(
            (w) => w.CodigoTipoDocumento === activeTab
          )}
          classes={classes}
          //   openUpload={openUpload}
          openListaArchivos={openListaArchivos}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={3}>
        <DetalleDocumentosComponente
          archivos={archivos}
          archivosBasicos={archivosBasicos.filter(
            (w) => w.CodigoTipoDocumento === activeTab
          )}
          classes={classes}
          //   openUpload={openUpload}
          openListaArchivos={openListaArchivos}
        />
      </TabPanel>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={dialogListaArchivos}
        onClose={() => setDialogListaArchivos(false)}
      >
        <DialogTitle align="center">
          <>
            <Typography variant="h5">Listado de archivos</Typography>
          </>
        </DialogTitle>
        <DialogContent>
          {selected &&
          archivos &&
          archivos.filter(
            (j) => j.codigoDocumento === selected.codigoDocumento
          )[0] ? (
            <VisorListadoArchivos
              files={
                archivos.filter(
                  (j) => j.codigoDocumento === selected.codigoDocumento
                )[0].grupo
              }
              onDownload={showResource}
              onDelete={deleteArchivo}
              contenedorURL={contenedorURL}
            />
          ) : (
            <Typography>No hay archivos cargados</Typography>
          )}
        </DialogContent>
        <DialogActions>
          {/* {selected && (
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUpload />}
              //   onClick={() => openUploadOnDialog()}
            >
              Subir Archivo
            </Button>
          )} */}
          <Button
            variant="contained"
            onClick={() => setDialogListaArchivos(false)}
            sx={{ backgroundColor: "red" }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default EmpleadoDocumentosComponente;
