import React, { useState } from "react";
import {
  Collapse,
  Button,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import { ExpandMore, ExpandLess } from "@material-ui/icons";

const ExpandableComponent = ({ title, children }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card style={{ width: "100%" }}>
      <Button
        onClick={handleExpandClick}
        style={{ width: "100%", textAlign: "left" }}
      >
        <Typography variant="h6">{title}</Typography>
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </Button>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>{children}</CardContent>
      </Collapse>
    </Card>
  );
};

export default ExpandableComponent;
