import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Paper,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  Check,
  AssignmentReturned,
  TransferWithinAStation,
  Delete,
  AssignmentTurnedIn,
} from "@material-ui/icons";

import { green } from "@material-ui/core/colors";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
  RowDragging,
} from "devextreme-react/data-grid";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import {
  validaPermisoPaginaActual,
  verificaPermisoPaginaActual,
} from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";

//pestañas
import ContainerPestaniaMovimientos from "../containers/pestania-movimiento-personal-container";

const width_proportion = "100%";
const titleColor = "#2B3C4D";
let datagridEmpleadosSCILU;

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    // minWidth: 480,

    marginBottom: 10,
    marginTop: 10,
    width: "100%",
    marginLeft: 30,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const MovimientoPersonal = (props) => {
  const classes = useStyles();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: fieldsErrors },
    register,
  } = useForm();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const monedaFormat = {
    style: "currency",
    currency: "GTQ",
    // useGrouping: true,
    minimumSignificantDigits: 3,
  };

  const [codigoNivelOrganizacion, setCodigoNivelOrganizacion] = useState(1);
  const [maestroGerencia, setMaestroGerencia] = useState([]);
  const [textoNivelOrganizacion, setTextoNivelOrganizacion] = useState("");
  const [registroActual, setRegistroActual] = useState({});
  const [nombre, setNombre] = useState("");
  const [codigoUnidadOrganizacion, setCodigoUnidadOrganizacion] = useState(0);

  const [userName, setUserName] = useState("");

  const [organizacionHijos, setOrganizacionHijos] = useState([]);

  const [maestroTipoTurno, setMaestroTipoTurno] = useState([]);

  const [codigoGerencia, setCodigoGerencia] = useState(0);

  const [listadoEmpleadoNoAsignado, setListadoEmpleadoNoAsignado] = useState(
    []
  );
  const [resumenMovimientos, setResumenMovimientos] = useState([]);

  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [buscando, setBackDrop] = useState(false);

  const [maestroJefeInmediato, setMaestroJefeInmediato] = useState([]);
  const [dpiJefeInmediato, setDpiJefeInmediato] = useState("");
  const [valueAutoCompleteJefeInmediato, setValueAutoCompleteJefeInmediato] =
    useState(null);

  const [maestroPlazas, setMaestroPlazas] = useState([]);
  const [codigoPlazaLaboral, setCodigoPlazaLaboral] = useState(0);

  const [maestroTipoObjetoSAP, setMaestroTipoObjetoSAP] = useState([]);
  const [codigoTipoObjetoSAP, setCodigoTipoObjetoSAP] = useState(0);

  const [maestroObjetoSAP, setMaestroObjetoSAP] = useState([]);
  const [objetoSAP, setObjetoSAP] = useState("");
  const [valueAutoCompleteObjetoSAP, setValueAutoCompleteObjetoSAP] =
    useState(null);

  const [
    listadoEmpleadoAsignadoPorUnidad,
    setListadoEmpleadoAsignadoPorUnidad,
  ] = useState([]);
  const [movimientoActual, setMovimientoActual] = useState(null);

  const [dialogoAbiertoCrearGestion, abrirDialogoCrearGestion] =
    useState(false);
  const [dialogoEliminarMovimiento, setDialogoEliminarMovimiento] =
    useState(false);

  const [selectedEmployeeNames, setSelectedEmployeeNames] = useState(
    "No hay empleados seleccionados"
  );

  const [empleadosSeleccion, setempleadosSeleccion] = useState([]);

  const [editarCompleto, setEditarCompleto] = useState(false);
  const [cargando, setCargando] = useState(false);
  //const [valido, setValido] = useState(false);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  useEffect(() => {
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "GESPILU";
      const llaveEditarCompleto = "GESPILU.MovimientosPersonal.Editar_Completo";
      let permisoEditarCompleto = await verificaPermisoPaginaActual(
        llaveAgrupacion,
        llaveEditarCompleto
      );
      setEditarCompleto(permisoEditarCompleto);
      // FIN DE CONSULTA DE PERMISOS

      handleAbrirBackDrop();

      await NuevaLista();
      // await MaestroJefeInmediato();
      // await cargarEmpleadoAsignadoPorUnidadOrganizacion();
      handleCerrarBackDrop();
    };

    cargar();
  }, [props.codigoUnidadOrganizacion]);

  // useEffect(() => {
  //   const cargar = async () => {
  //     handleAbrirBackDrop();

  //     if (codigoPlazaLaboral > 0) {
  //       await MaestroJefeInmediato();
  //       setValueAutoCompleteJefeInmediato(null);
  //       setDpiJefeInmediato("");
  //     }

  //     handleCerrarBackDrop();
  //   };

  //   cargar();
  // }, [codigoPlazaLaboral]);

  useEffect(() => {
    const cargar = async () => {
      handleAbrirBackDrop();

      if (codigoTipoObjetoSAP > 0) {
        await cargarMaestroObjetoSAP();
        setValueAutoCompleteObjetoSAP(null);
        setObjetoSAP("");
      }

      handleCerrarBackDrop();
    };

    cargar();
  }, [codigoTipoObjetoSAP]);

  useEffect(() => {
    const cargar = async () => {
      if (registroActual !== undefined || registroActual !== null) {
        handleAbrirBackDrop();
        await cargaOrganizacionHijos();
        await cargarPlazas();

        handleCerrarBackDrop();
      }
    };

    cargar();
  }, [registroActual]);

  useEffect(() => {
    let data = maestroGerencia;
    if (data.length > 0) {
      var nivelActual = data.find(
        (f) => f.CodigoNivelOrganizacion === codigoNivelOrganizacion
      );

      if (nivelActual !== undefined || nivelActual !== null) {
        setTextoNivelOrganizacion(nivelActual.NivelOrganizacion);
      }
    }
  }, [codigoNivelOrganizacion]);

  const cargarEmpleadoAsignadoPorUnidadOrganizacion = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/PlazaLaboral/AsignadosPorUnidadOrganizacion/" +
        props.codigoUnidadOrganizacion;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setListadoEmpleadoAsignadoPorUnidad(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };
  const cargarMaestroGerencia = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/Empleado/MaestroGerencia";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroGerencia(request.data.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroTipoTurno = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/TipoTurno/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroTipoTurno(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroTipoObjetoSAP = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/TipoObjetoSAP/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroTipoObjetoSAP(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroObjetoSAP = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/TipoObjetoSAP/" + codigoTipoObjetoSAP;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroObjetoSAP(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };
  const cargaOrganizacionHijos = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/EmpleadoTurno/Actual/" + registroActual.CodigoUnidadOrganizacion;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setOrganizacionHijos(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarPlazas = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/EmpleadoSolicitudMovimiento/PlazasParaTraslado/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroPlazas(request.data.ficha);
        console.log(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const NuevaLista = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/EmpleadoSolicitudMovimiento/" + props.codigoUnidadOrganizacion;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setListadoEmpleadoNoAsignado(request.data.ficha);
        setResumenMovimientos(request.data.ficha2);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const MaestroJefeInmediato = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/EmpleadoJefeInmediato/PorPlazaLaboral/" + codigoPlazaLaboral;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroJefeInmediato(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const onExporting = async (e) => {};

  const handleCerrarDialogoEliminarMovimiento = () => {
    setDialogoEliminarMovimiento(false);
  };

  const handleAbrirDialogoCrearGestion = () => {
    console.log(datagridEmpleadosSCILU.getSelectedRowsData());
    setempleadosSeleccion(datagridEmpleadosSCILU.getSelectedRowsData());

    abrirDialogoCrearGestion(true);
  };

  const handleCerrarDialogoCrearGestion = () => {
    abrirDialogoCrearGestion(false);
  };

  const handleAbrirDialogoEliminarMovimiento = async (pFila) => {
    setMovimientoActual(pFila);
    setDialogoEliminarMovimiento(true);
  };

  const eliminarRegistroMovimientoEmpleado = async () => {
    const data = {
      CodigoEmpleadoSolicitudMovimiento:
        movimientoActual.CodigoEmpleadoSolicitudMovimiento,
      DPI: movimientoActual.DPI,
      DPIJefeInmediato: movimientoActual.DPIJefeInmediato,
      CodigoTurno: movimientoActual.CodigoTipoTurno,
      CodigoTipoMovimientoPersonal: movimientoActual.CodigoTipoMovimiento,
      CodigoEstadoMovimientoPersonal:
        movimientoActual.CodigoEstadoMovimientoPersonal,
      CodigoPlazaLaboral: movimientoActual.CodigoPlazaLaboral,
      FechaEjecución: movimientoActual.FechaEjecucion,
      Referencia: 0,
      Activo: true,
      Eliminado: false,
      UsuarioInserto: userName,
      FechaInserto: "",
      UsuarioModifico: "",
      FechaModifico: "",
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post(
          "api/EmpleadoSolicitudMovimiento/Eliminar",
          data
        );
        if (request.status !== 200) {
          toast.error("Este registro no se puede eliminar");
        } else {
          if (request.data.response) {
            toast.success("Se realizo la operación exitosamente");
          } else {
            toast.error("Error: " + request.data.mensaje);
          }

          await NuevaLista();
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarDialogoEliminarMovimiento();
      handleCerrarBackDrop();
    }
  };
  // const handleAbrirDialogoBaja = (fila) => {
  //   handleOpenModalPuestoLaboral();

  //   setDpi(fila.DPI);
  //   setCodigoTipoMovimiento(2);
  // };

  const generarSolicitud = async () => {
    const data = {
      ListaSolicitudes: empleadosSeleccion,
      UsuarioInserto: userName,
      CodigoUnidadOrganizacion: props.codigoUnidadOrganizacion,
      // CodigoGerencia: props.CodigoGerencia,
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      setCargando(true);
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post(
          "api/EmpleadoSolicitudMovimiento/Gestion",
          data
        );
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          if (request.data.response) {
            toast.success("Se guardó exitosamente");

            await NuevaLista();

            setCodigoGerencia(0);
            setCodigoUnidadOrganizacion(0);

            deseleccionarItemsEnGridEmpleados();
          } else {
            toast.error(request.data.mensaje);
          }
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarDialogoCrearGestion();
      handleCerrarBackDrop();
      setCargando(false);
    }
  };

  const cellOpcionesMovimientos = (rowInfo) => {
    return (
      <>
        <Tooltip
          title="Eliminar item"
          style={{ marginRight: 5, cursor: "pointer" }}
        >
          <Delete
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() => handleAbrirDialogoEliminarMovimiento(rowInfo.data)}
          />
        </Tooltip>
      </>
    );
  };

  //selección multiple

  const onInitialized = (e) => {
    datagridEmpleadosSCILU = e.component;
  };

  const deseleccionarItemsEnGridEmpleados = () => {
    datagridEmpleadosSCILU.clearSelection();
  };

  const getEmployeeName = (row) => {
    return `[${row.CodigoAnterior} - ${row.NombreCompleto}]`;
  };

  const getEmployeeNames = (selectedRowsData) => {
    return selectedRowsData.length
      ? selectedRowsData.map(getEmployeeName).join("; ")
      : "No hay empleados seleccionados";
  };

  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    setSelectedEmployeeNames(getEmployeeNames(selectedRowsData));
  };

  const cellTemplateUnidadOrganizacion = (rowInfo) => {
    return (
      <>
        <Tooltip
          title={`Ir a gestión:`}
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          <Link to={`/gestion/${rowInfo.data.Referencia}`}>
            {rowInfo.data.Referencia}
          </Link>
        </Tooltip>
      </>
    );
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* Dialogo para confirmar la generación de solicitud */}
      <Dialog
        open={dialogoAbiertoCrearGestion}
        onClose={handleCerrarDialogoCrearGestion}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Crear gestión</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de iniciar la gestión de traslado de personal de los
            empleados seleccionados?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrarDialogoCrearGestion} color="secondary">
            Cancelar
          </Button>
          <Button
            disabled={cargando}
            onClick={() => generarSolicitud()}
            color="primary"
          >
            {cargando ? "Creando gestión ..." : "Iniciar gestión"}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialogo para eliminar movimiento  */}
      <Dialog
        open={dialogoEliminarMovimiento}
        onClose={handleCerrarDialogoEliminarMovimiento}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Eliminar Movimiento</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas eliminar este registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCerrarDialogoEliminarMovimiento}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => eliminarRegistroMovimientoEmpleado()}
            color="secondary"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Componente para pestañas de empleados actuales y posibles traslados */}
      <Paper className={classes.rootTabla}>
        <ContainerPestaniaMovimientos
          codigoUnidadOrganizacion={props.codigoUnidadOrganizacion}
          actualizarListaSolicitudes={NuevaLista}
          editarCompleto={editarCompleto}
        />
      </Paper>
      {/* Grid Movimientos */}
      <Paper className={classes.rootTabla}>
        <Typography variant="h5">Lista de Movimientos</Typography>
        <DataGrid
          dataSource={listadoEmpleadoNoAsignado}
          //   defaultColumns={this.props.columns}
          showBorders={true}
          rowAlternationEnabled={true}
          //ref={(ref) => (dataGrid = ref)}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onExporting={onExporting}
          className={classes.table}
          onInitialized={onInitialized}
          onSelectionChanged={onSelectionChanged}
        >
          <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
          <HeaderFilter visible={showHeaderFilter} />
          <GroupPanel visible={false} />
          <Grouping autoExpandAll={autoExpandAll} />
          <SearchPanel visible={false} width={240} placeholder="Search..." />
          {/* <Selection mode="multiple" /> */}
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating={false}
            allowDeleting={false}
          />
          <Selection mode="multiple" />
          <Column
            caption="Opciones"
            allowFiltering={false}
            allowSorting={false}
            cellRender={cellOpcionesMovimientos}
            fixed={true}
            fixedPosition="left"
          />
          <Column
            dataField="Referencia"
            caption="# Gestion"
            cellRender={cellTemplateUnidadOrganizacion}
            fixed={true}
            fixedPosition="left"
          />
          <Column dataField="DPI" caption="DPI" />
          <Column
            dataField="NombreCompleto"
            caption="Nombre Completo"
            fixed={true}
            fixedPosition="left"
          />
          <Column
            dataField="CodigoAnterior"
            caption="Código Empleado"
            // fixed={true}
            // fixedPosition="left"
          />

          <Column
            dataField="TipoMovimientoPersonal"
            caption="Tipo Movimiento"
          />
          <Column
            dataField="EstadoMovimientoPersonal"
            caption="Estado Movimiento "
          />
          <Column
            dataField="FechaEjecucion"
            caption="Fecha de Movimiento"
            dataType="date"
            format="dd/MM/yyyy"
          />
          <Column
            dataField="CodigoDepartamentoAnterior"
            caption="Cod. Departamento Actual"
            // visible={false}
          />
          <Column
            dataField="DepartamentoAnterior"
            caption="Departamento Actual"
            // visible={false}
          />
          <Column
            dataField="CodigoDepartamento"
            caption="Cod. Departamento Nuevo"
            // visible={false}
          />
          <Column
            dataField="Departamento"
            caption="Departamento Nuevo "
            // visible={false}
          />

          <Column
            dataField="SalarioAnterior"
            caption="SalarioActual"
            format={monedaFormat}
          />
          <Column
            dataField="SalarioSolicitado"
            caption="Salario Solicitado"
            format={monedaFormat}
            backgroundColor={green}
          />
          {/* <Column
            dataField="CodigoAnteriorJefeInmediato"
            caption="Código Jefe"
          /> */}
          <Column dataField="JefeInmediato" caption="Jefe Inmediato" />
          <Column dataField="MotivoMovimientoPersonal" caption="Motivo " />
          <Column dataField="RazonMovimientoPersonal" caption="Razón " />
          <Column dataField="NoContratable" caption="No Requerido " />
          <Column
            dataField="CodigoPuestoLaboralAnterior"
            caption="Código Puesto Laboral Actual"
          />
          <Column
            dataField="PuestoLaboralAnterior"
            caption="Puesto Laboral Actual"
          />
          <Column
            dataField="CodigoPuestoLaboral"
            caption="Código Puesto Laboral Nuevo"
          />
          <Column dataField="PuestoLaboral" caption="Puesto Laboral Nuevo" />
          <Column
            dataField="ClasificacionPuestoLaboral"
            caption="Clasificación"
          />
          <Column dataField="TipoTurno" caption="Tipo Turno" />
          <Column
            dataField="CodigoTipoObjetoSAP"
            caption="CodigoTipoObjetoSAP"
            visible={false}
          />
          <Column dataField="TipoObjetoSAP" caption="Tipo Objeto SAP" />
          <Column dataField="ObjetoSAP" caption="Objeto SAP" />
          <Column dataField="TextoObjetoSAP" caption="Texto SAP" />
          <Column
            dataField="CodigoTemporadaAnterior"
            caption="CodigoTemporadaAnterior"
            visible={false}
          />
          <Column dataField="TemporadaAnterior" caption="Temporada Anterior" />
          <Column
            dataField="CodigoTemporada"
            caption="CodigoTemporada"
            visible={false}
          />
          <Column dataField="Temporada" caption="Temporada" />
          <Column dataField="EsReemplazo" caption="Es Reemplazo" />
          <Column datafield="NombreReemplazado" caption="Reemplazado" />
          <Column dataField="Comentario" caption="Comentario" />
          <Column dataField="AgrupacionDePago" caption="Agrupación De Pago" />
          <Column dataField="CentroDePago" caption="Centro De Pago" />
          {/* <Column dataField="Expertis" caption="Expertis" />
          <Column dataField="ExpertisAnterior" caption="ExpertisAnterior" /> */}

          <ColumnFixing enabled={true} />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={5} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[5, 10]}
            showInfo={true}
          />
          <Export enabled={true} allowExportSelectedData={true} />
          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>
        <div className={classes.selectedData}>
          <span className={classes.selectedDataCaption}>
            Empleados seleccionados:
          </span>{" "}
          <span>{selectedEmployeeNames}</span>
        </div>
        {editarCompleto && (
          <React.Fragment>
            <Typography variant="h6">Acciones</Typography>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {/* <input type="submit" /> */}
              <Tooltip title="Crear Gestiones ">
                <Button
                  // fullWidth
                  variant="contained"
                  style={{
                    backgroundColor: "#69BD4B",
                    color: "white",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  // type="submit"
                  size="small"
                  onClick={handleAbrirDialogoCrearGestion}
                  startIcon={<AssignmentTurnedIn />}
                >
                  Crear Solicitud
                </Button>
              </Tooltip>
            </Grid>
          </React.Fragment>
        )}
      </Paper>
      {/* Grid Resumen */}
      <Paper className={classes.rootTabla}>
        <Typography variant="h5">Resumen</Typography>
        <DataGrid
          dataSource={resumenMovimientos}
          //   defaultColumns={this.props.columns}
          showBorders={true}
          rowAlternationEnabled={true}
          //ref={(ref) => (dataGrid = ref)}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onExporting={onExporting}
          className={classes.table}
        >
          <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
          <HeaderFilter visible={showHeaderFilter} />
          <GroupPanel visible={false} />
          <Grouping autoExpandAll={autoExpandAll} />
          <SearchPanel visible={false} width={240} placeholder="Search..." />
          {/* <Selection mode="multiple" /> */}
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating={false}
            allowDeleting={false}
          />

          <Column
            dataField="TipoMovimientoPersonal"
            caption="Tipo Movimiento"
          />

          <Column dataField="SubTotal" caption="SubTotal" />
          <ColumnFixing enabled={true} />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[10]}
            showInfo={true}
          />
          <Export enabled={true} allowExportSelectedData={true} />
          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>
      </Paper>
    </React.Fragment>
  );
};

export default MovimientoPersonal;
