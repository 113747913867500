import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import { DropzoneArea } from "material-ui-dropzone";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";
import ImageIcon from "@material-ui/icons/Image";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import FunctionsIcon from "@material-ui/icons/Functions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  CanvasPath,
  ExportImageType,
  ReactSketchCanvas,
  ReactSketchCanvasProps,
  ReactSketchCanvasRef,
} from "react-sketch-canvas";
//Own
import { isSignedIn } from "../../utils/auth";
import API from "../../utils/api";
import { toast } from "react-toastify";
import DataGrid from "../../helpers/components/data-grid";

/*//Props que recibe este componente:

tarea={tarea}
camposPersonalizados={camposPersonalizados}
recargar={makeRequestTarea}
onPressInactivarCampoValor={onPressInactivarCampoValor}
quitarDocAdjuntoDeEstado={() => {}}
permisoEditar={permisoEditar}
onChangeCampoPersonalizado={onChangeCampoPersonalizado}
CodigoPadre = {codigoPadre}
TablaPadre = {"AITAvisoTarea"} o cualquier otra tabla.
*/

const useStyles = makeStyles((theme) => ({
  tituloPaper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
  },
  gestionGeneral: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  container: {
    margin: 10,
  },
  input: {
    marginTop: 15,
  },
  inputField: {
    textAlign: "start",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
}));

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, ":", /[0-5]/, /[0-9]/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function newTab(url) {
  var form = document.createElement("form");
  form.method = "GET";
  form.action = url;
  form.target = "_blank";
  document.body.appendChild(form);
  form.submit();
}

async function newTabAuth({ fileName, key }) {
  const session = await isSignedIn();
  const baseApi = await API.baseApi();

  axios({
    url: `${baseApi}api/media/descargar?key=${key}&fileName=${fileName}`, //your url
    method: "GET",
    headers: { Authorization: "bearer " + session.accessToken },
    responseType: "blob", // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
  });
}

function TareaFormulario(props) {
  const refs = useRef([]);
  const [camposPersonalizados, setCamposPersonalizados] = useState(null);
  const [codigoTarea, setCodigoTarea] = useState(null);

  const handleKeyDown = useCallback((e, index) => {
    if (e.key === "Tab") {
      e.preventDefault();
      if (refs.current[index + 1]) {
        // console.log(refs.current[index + 1]);
        refs.current[index + 1].focus();
      }
    }
  }, []);

  useEffect(() => {
    setCamposPersonalizados(props.camposPersonalizados);
    console.log("Los campos personalizados JMB", props.camposPersonalizados);
  }, [props.camposPersonalizados]);

  useEffect(() => {
    setCodigoTarea(props.tarea.AvisoTarea.CodigoAvisoTarea);
  }, [props.tarea]);

  const grabarFila = async (
    codigoCampoBase,
    plantillaValores,
    fila,
    codigoGrid
  ) => {
    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        let data = {
          codigoGrid: codigoGrid, // código del avisoCampoValor / FormularioCampoValor del grid
          codigoCampoGrid: codigoCampoBase, //Generalmente se establece el mismo codigo de campo del grid al momento de crear una columna ya que no existe el tipo de campo columna
          origen: "AITFormularioCampoValor", //ej: AITAvisoCampoValor / AITFormularioCampoValor
          camposColumnas: plantillaValores,
          codigoUsuarioOpera: vIsSignedIn.codigoUsuario,
          usuarioOpera: vIsSignedIn.userName,
        };

        let request = await API.post("api/formulario/agregarFilaGrid", data);
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, consulta con un administrador"
          );
          return;
        }
      }

      props.recargar();
    } catch (err) {
      console.error(err);
    }
  };

  const removerFila = async (
    objectToDelete,
    codigoFormDeCampo,
    codigoCampo
  ) => {
    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        let data = {
          Id: objectToDelete.Codigo,
          CodigoUsuarioOpera: vIsSignedIn.codigoUsuario,
          UsuarioOpera: vIsSignedIn.userName,
        };

        let request = await API.post("api/formulario/removerFilaGrid", data);
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, consulta con un administrador"
          );
          return;
        }
        toast.info("El registro fue removido con éxito");
        props.recargar();
      }
    } catch (err) {
      console.log(err);
    }
  };

  function DibujarCampo(props) {
    const [row, setRow] = useState(props.row);
    const myRefs = useRef([]);

    const index = props.index;

    useEffect(() => {
      setRow(props.row);
    }, [props.row]);

    const [iKey, setIkey] = useState(0);
    const iterarKey = () => {
      setIkey(iKey + 1);
    };

    const uploadFiles = async (
      codigoTarea,
      campo,
      recargarDatos,
      iterarKey,
      files
    ) => {
      console.log("uploading");
      const session = await isSignedIn();
      //let codigoTarea  = tarea.AvisoTarea.CodigoAvisoTarea;

      if (campo.Valor != null && files.length > 0) {
        let data = new FormData();
        //data.append("CodigoObjeto", codigoTarea);
        //data.append("NombreTablaObjeto", props.TablaPadre);
        data.append("CodigoObjeto", props.CodigoPadre);
        data.append("NombreTablaObjeto", props.TablaPadre);
        data.append("UsuarioInserto", session["userName"]);
        data.append("CodigoCampo", campo.CodigoCampo);
        data.append(
          "CodigoFormularioCampoValor",
          campo.CodigoFormularioCampoValor
        );
        data.append("CodigoTipoCampo", campo.CodigoTipoCampo);

        await files.forEach(async (element2, j) => {
          await data.append("files[]", element2);
        });

        let request = await API.post(
          "/api/formulario/agregarMultimedia",
          data,
          {
            "content-type": "multipart/form-data",
          }
        );
        await console.log(request);
        await recargarDatos();
        iterarKey();
      }
    };

    function dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    }

    const uploadSketch = async (
      codigoTarea,
      campo,
      recargarDatos,
      iterarKey,
      id
    ) => {
      console.log("uploading");
      const session = await isSignedIn();
      //let codigoTarea  = tarea.AvisoTarea.CodigoAvisoTarea;

      if (campo.Valor != null) {
        let data = new FormData();
        //data.append("CodigoObjeto", codigoTarea);
        //data.append("NombreTablaObjeto", props.TablaPadre);
        data.append("CodigoObjeto", props.CodigoPadre);
        data.append("NombreTablaObjeto", props.TablaPadre);
        data.append("UsuarioInserto", session["userName"]);
        data.append("CodigoCampo", campo.CodigoCampo);
        data.append(
          "CodigoFormularioCampoValor",
          campo.CodigoFormularioCampoValor
        );
        data.append("CodigoTipoCampo", campo.CodigoTipoCampo);

        const exportImage = myRefs.current[id].exportImage;

        if (exportImage) {
          const exportedDataURI = await exportImage("png");
          const filename = "firma" + new Date().getTime().toString(36) + ".png";
          const reader = await dataURLtoFile(exportedDataURI, filename);

          if (reader !== undefined && exportedDataURI !== undefined) {
            await data.append("files[]", reader);
          }
        }

        // await files.forEach(async (element2, j) => {
        //   await data.append("files[]", element2);
        // });

        let request = await API.post(
          "/api/formulario/agregarMultimedia",
          data,
          {
            "content-type": "multipart/form-data",
          }
        );

        console.log("Sketch: " + request);
        await recargarDatos();
        iterarKey();
      }
    };

    let component;
    switch (row.CodigoTipoCampo) {
      case 1:
        component = (
          <TextField
            required={props.row.Requerido}
            id={`txt${props.row.CodigoCampo}`}
            label={props.row.Nombre}
            style={{ width: "100%" }}
            margin="normal"
            variant="outlined"
            placeholder={
              props.row.PlaceHolderIgualNombre == true
                ? props.row.Nombre
                : props.row.PlaceHolderText
            }
            inputProps={{ zIndex: 0 }}
            defaultValue={row.Valor.Valor}
            onBlur={props.onChangeCampoPersonalizado(row.CodigoCampo)}
            disabled={!props.permisoEditar ? true : false}
            inputRef={(el) => (refs.current[index] = el)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            //    verificarDependencias(campo);
            //  }}
          />
        );

        break;

      case 2:
        component = (
          <TextField
            required={props.row.Requerido}
            id={`txt${props.row.CodigoCampo}`}
            type="number"
            label={props.row.Nombre}
            style={{ width: "100%" }}
            margin="normal"
            variant="outlined"
            placeholder={
              props.row.PlaceHolderIgualNombre == true
                ? props.row.Nombre
                : props.row.PlaceHolderText
            }
            inputProps={{ zIndex: 0 }}
            defaultValue={row.Valor.Valor}
            disabled={!props.permisoEditar ? true : false}
            onBlur={props.onChangeCampoPersonalizado(row.CodigoCampo)}
            inputRef={(el) => (refs.current[index] = el)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            //  onBlur={() => {
            //    //Esta función se manda a llamar cuando algún otro campo depende del cambio del campo actual.
            //    let { plantillaValores } = this.state;

            //    let campo = plantillaValores.find(
            //      (f) => f.CodigoCampo == row.CodigoCampo
            //    );

            //    this.verificarDependencias(campo);
            //  }}
          />
        );
        break;

      case 3:
        component = (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={0}>
              <TextField
                required={props.row.Requerido}
                id={props.row.Nombre}
                label={props.row.Nombre}
                type="date"
                style={{ width: "100%" }}
                disabled={!props.permisoEditar ? true : false}
                defaultValue={row.Valor.Valor}
                onBlur={props.onChangeCampoPersonalizado(row.CodigoCampo)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputRef={(el) => (refs.current[index] = el)}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
            </Grid>
          </Grid>
        );
        break;
      case 4:
        component = (
          <TextField
            required={props.row.Requerido}
            id={`txt${props.row.CodigoCampo}`}
            label={props.row.Nombre}
            style={{ width: "100%" }}
            margin="normal"
            variant="outlined"
            placeholder={
              props.row.PlaceHolderIgualNombre == true
                ? props.row.Nombre
                : props.row.PlaceHolderText
            }
            inputProps={{ zIndex: 0 }}
            multiline
            rows="3"
            rowsMax={20}
            // margin="normal"
            // variant="outlined"
            // inputProps={{ zIndex: 0 }}
            disabled={!props.permisoEditar ? true : false}
            defaultValue={row.Valor.Valor}
            onBlur={props.onChangeCampoPersonalizado(row.CodigoCampo)}
            inputRef={(el) => (refs.current[index] = el)}
            onKeyDown={(e) => handleKeyDown(e, index)}
          />
        );
        break;

      case 5:
        let find = camposPersonalizados.find(
          (f) => f.CodigoCampo == props.row.CodigoCampo
        );

        let selectedItems = [];
        if (find && find.Valor) {
          selectedItems = find.Valor;
        } else {
          selectedItems = [];
        }
        component = (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl
                style={{ width: "100%" }}
                required={props.row.Requerido}
              >
                <InputLabel htmlFor="">{props.row.Nombre}</InputLabel>
                <Select
                  // multiple
                  placeholder={
                    props.row.PlaceHolderIgualNombre == true
                      ? props.row.Nombre
                      : props.row.PlaceHolderText
                  }
                  name={"select" + props.row.Nombre}
                  input={
                    <Input
                      name={"select" + props.row.Nombre}
                      id={"select" + props.row.Nombre}
                    />
                  }
                  disabled={!props.permisoEditar ? true : false}
                  defaultValue={row.Valor.Valor}
                  onChange={props.onChangeCampoPersonalizado(row.CodigoCampo)}
                  inputRef={(el) => (refs.current[index] = el)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                >
                  {props.row.Items.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText />
              </FormControl>
            </Grid>
          </Grid>
        );
        break;

      case 6:
        component = (
          <React.Fragment>
            <FormControl
              style={{ width: "100%" }}
              required={props.row.Requerido}
            >
              <label>{props.row.Nombre}</label>
              {!props.permisoEditar ? (
                <></>
              ) : (
                <DropzoneArea
                  dropzoneText={props.row.Nombre}
                  onChange={uploadFiles.bind(
                    this,
                    props.codigoTarea,
                    row,
                    props.recargarDatos,
                    iterarKey
                  )}
                  acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                  maxFileSize={1073741824}
                  filesLimit={1}
                  key={iKey + row.CodigoCampo}
                  inputRef={(el) => (refs.current[index] = el)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
              )}
            </FormControl>
            <div>
              {Array.isArray(props.row.Valor) ? (
                props.row.Valor.map((fila, i) => (
                  <Chip
                    icon={<ImageIcon />}
                    label={fila.FileName.substring(0, 20) + "..."}
                    color="primary"
                    onClick={() => {
                      newTab(fila.Path);
                    }}
                    onDelete={async () => {
                      if (!props.permisoEditar) {
                        toast.warn(
                          "No tienes permisos para realizar esta acción."
                        );
                        return;
                      }
                      const response = await props.onPressInactivarCampoValor({
                        CodigoFormularioCampoValor:
                          fila.CodigoFormularioCampoValor,
                        FormularioCampoValorMultimedia: {
                          CodigoFormularioCampoValorMultimedia:
                            fila.CodigoFormularioCampoValorMultimedia,
                          Activo: false,
                        },
                      });
                      props.recargarDatos();
                      //props.quitarDocAdjuntoDeEstado(props.row, fila);
                    }}
                    style={{ margin: 15 }}
                  />
                ))
              ) : (
                <></>
              )}
            </div>
          </React.Fragment>
        );
        break;
      case 7:
        component = (
          <FormControlLabel
            style={{ display: "flex", justifyContent: "flexStart" }}
            required={props.row.Requerido}
            control={
              <Checkbox
                required={props.row.Requerido}
                disabled={!props.permisoEditar ? true : false}
                checked={row.Valor.Valor}
                defaultValue={row.Valor.Valor}
                onChange={props.onChangeCampoPersonalizado(row.CodigoCampo)}
                value="activo"
                color="primary"
                inputRef={(el) => (refs.current[index] = el)}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
            }
            label={props.row.Nombre}
          />
        );
        break;

      case 8:
        component = (
          <React.Fragment>
            <FormControl style={{ width: "100%" }} required={row.Requerido}>
              <label>{props.row.Nombre}</label>
              {!props.permisoEditar ? (
                <></>
              ) : (
                <DropzoneArea
                  dropzoneText={props.row.Nombre}
                  onChange={uploadFiles.bind(
                    this,
                    props.codigoTarea,
                    row,
                    props.recargarDatos,
                    iterarKey
                  )}
                  maxFileSize={1073741824}
                  acceptedFiles={[
                    "image/jpeg",
                    "image/png",
                    "image/bmp",
                    ".sql",
                    ".txt",
                    ".pdf",
                    ".doc",
                    ".docx",
                    ".xls",
                    ".xlsx",
                    ".csv",
                    ".ppt",
                    ".pptx",
                    ".pages",
                    ".odt",
                    ".rtf",
                    ".ai",
                    ".psd",
                    ".mp3",
                    ".mp4",
                    ".avi",
                    ".eps",
                    ".ait",
                    ".svg",
                    ".svgz",
                  ]}
                  filesLimit={1}
                  key={iKey + row.CodigoCampo}
                  inputRef={(el) => (refs.current[index] = el)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
              )}
            </FormControl>
            <div>
              {Array.isArray(props.row.Valor) ? (
                props.row.Valor.map((fila, i) => (
                  <Chip
                    icon={<AttachFileIcon />}
                    label={fila.FileName.substring(0, 20) + "..."}
                    color="primary"
                    onClick={() => {
                      //newTab(fila.Path);
                      newTabAuth({
                        key: "IMAGE_REPOSITORY_FORMULARIO",
                        fileName: fila.FileName,
                      });
                    }}
                    onDelete={async () => {
                      if (!props.permisoEditar) {
                        toast.warn(
                          "No tienes permisos para realizar esta acción."
                        );
                        return;
                      }
                      const response = await props.onPressInactivarCampoValor({
                        CodigoFormularioCampoValor:
                          fila.CodigoFormularioCampoValor,
                        FormularioCampoValorMultimedia: {
                          CodigoFormularioCampoValorMultimedia:
                            fila.CodigoFormularioCampoValorMultimedia,
                          Activo: false,
                        },
                      });
                      props.recargarDatos();
                      //props.quitarDocAdjuntoDeEstado(props.row, fila);
                    }}
                    style={{ margin: 15 }}
                  />
                ))
              ) : (
                <></>
              )}
            </div>
          </React.Fragment>
        );
        break;

      case 10: {
        let find = camposPersonalizados.find(
          (f) => f.CodigoCampo == props.row.CodigoCampo
        );

        let selectedItems = [];
        if (find && find.Items) {
          selectedItems = find.Items;
        } else {
          selectedItems = [];
        }

        if (props.row.Items == undefined || props.row.Items == null) {
          props.row.Items = [];
        }
        component = (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl
                style={{ width: "100%" }}
                required={props.row.Requerido}
              >
                <InputLabel htmlFor="">{props.row.Nombre}</InputLabel>
                <Select
                  // multiple
                  placeholder={
                    props.row.PlaceHolderIgualNombre == true
                      ? props.row.Nombre
                      : props.row.PlaceHolderText
                  }
                  name={"select" + props.row.Nombre}
                  value={row.Valor.Valor}
                  //  onChange={this.onSelectedItemsCamposDinamicos(props.row)}
                  input={
                    <Input
                      name={"select" + props.row.Nombre}
                      id={"select" + props.row.Nombre}
                    />
                  }
                  disabled={!props.permisoEditar ? true : false}
                  defaultValue={row.Valor.Valor}
                  onChange={props.onChangeCampoPersonalizado(row.CodigoCampo)}
                  inputRef={(el) => (refs.current[index] = el)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                >
                  {selectedItems.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText />
              </FormControl>
            </Grid>
          </Grid>
        );
        break;
      }
      case 12: {
        let find = camposPersonalizados.find(
          (f) => f.CodigoCampo == props.row.CodigoCampo
        );

        let selectedItems = [];
        if (find && find.Items) {
          selectedItems = find.Items;
        } else {
          selectedItems = [];
        }

        if (props.row.Items == undefined || props.row.Items == null) {
          props.row.Items = [];
        }

        component = (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl
                style={{ width: "100%" }}
                required={props.row.Requerido}
              >
                <InputLabel htmlFor="">{props.row.Nombre}</InputLabel>
                <Select
                  // multiple
                  placeholder={
                    props.row.PlaceHolderIgualNombre == true
                      ? props.row.Nombre
                      : props.row.PlaceHolderText
                  }
                  name={"select" + props.row.Nombre}
                  value={row.Valor.Valor}
                  input={
                    <Input
                      name={"select" + props.row.Nombre}
                      id={"select" + props.row.Nombre}
                    />
                  }
                  disabled={!props.permisoEditar ? true : false}
                  defaultValue={row.Valor.Valor}
                  onChange={props.onChangeCampoPersonalizado(row.CodigoCampo)}
                  inputRef={(el) => (refs.current[index] = el)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                >
                  {selectedItems.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText />
              </FormControl>
            </Grid>
          </Grid>
        );
        break;
      }
      case 13:
        component = (
          <TextField
            required={props.row.Requerido}
            id={`txt${props.row.CodigoCampo}`}
            label={props.row.Nombre}
            style={{ width: "100%" }}
            margin="normal"
            variant="outlined"
            placeholder={
              props.row.PlaceHolderIgualNombre == true
                ? props.row.Nombre
                : props.row.PlaceHolderText
            }
            inputProps={{ zIndex: 0 }}
            disabled={!props.permisoEditar ? true : false}
            defaultValue={row.Valor.Valor}
            onBlur={props.onChangeCampoPersonalizado(row.CodigoCampo)}
            inputRef={(el) => (refs.current[index] = el)}
            onKeyDown={(e) => handleKeyDown(e, index)}
          />
        );

        break;
      //Campo de tipo Hora
      case 14:
        component = (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel htmlFor="formatted-text-mask-input">
                {props.row.Nombre} (HH:mm)
              </InputLabel>
              <Input
                required={props.row.Required}
                id={props.row.CodigoCampo}
                inputComponent={TextMaskCustom}
                defaultValue={row.Valor.Valor}
                onBlur={props.onChangeCampoPersonalizado(row.CodigoCampo)}
                disabled={!props.permisoEditar ? true : false}
                inputRef={(el) => (refs.current[index] = el)}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
            </FormControl>
          </Grid>
        );
        break;
      //Campo de tipo Separador 01
      case 15:
        component = (
          // <Separador CodigoCampo={row.CodigoCampo} Nombre={row.Nombre} />
          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        );
        break;
      case 16:
        component = (
          <div className={props.classes.inputField}>
            <Typography variant="h6" gutterBottom>
              {props.row.Nombre}
            </Typography>
          </div>
        );
        break;
      case 17:
        component = (
          <div className={props.classes.inputField}>
            <Typography variant="body2" gutterBottom>
              {props.row.Nombre}
            </Typography>
          </div>
        );
        break;
      case 18: //Imagen fija
        component = (
          <div
            className={props.classes.inputField}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              alt="img"
              src={props.row.Valor.Valor}
              style={{ maxWidth: "100%" }}
            />
          </div>
        );
        break;
      case 19:
        {
          let find = camposPersonalizados.find(
            (f) => f.CodigoCampo == props.row.CodigoCampo
          );

          let selectedItems = [];
          if (find && find.Valor) {
            selectedItems = find.Valor;
          } else {
            selectedItems = [];
          }
          component = (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  style={{ width: "100%" }}
                  required={props.row.Requerido}
                >
                  <InputLabel htmlFor="">{props.row.Nombre}</InputLabel>
                  <Select
                    multiple
                    placeholder={
                      props.row.PlaceHolderIgualNombre == true
                        ? props.row.Nombre
                        : props.row.PlaceHolderText
                    }
                    name={"select" + props.row.Nombre}
                    input={
                      <Input
                        name={"select" + props.row.Nombre}
                        id={"select" + props.row.Nombre}
                      />
                    }
                    disabled={!props.permisoEditar ? true : false}
                    defaultValue={row.Valor.Valor}
                    onChange={props.onChangeCampoPersonalizado(row.CodigoCampo)}
                    inputRef={(el) => (refs.current[index] = el)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                  >
                    {props.row.Items.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText />
                </FormControl>
              </Grid>
            </Grid>
          );
        }
        break;
      case 20:
        {
          let find = camposPersonalizados.find(
            (f) => f.CodigoCampo == props.row.CodigoCampo
          );

          let cols = [];
          if (find && find.Items) {
            cols = find.Items;
          } else {
            cols = [];
          }

          let filas = [];
          if (find && find.Filas) {
            filas = find.Filas;
          } else {
            filas = [];
          }
          console.log("codigoFormDel campo", props.row.ValorPorDefecto);
          component = (
            <Grid container spacing={3}>
              <div>
                <strong>{row.Nombre}</strong>
              </div>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <DataGrid
                  columns={cols}
                  rows={filas}
                  codigoFormDeCampo={Number(props.row.ValorPorDefecto)}
                  codigoCampo={props.row.CodigoCampo}
                  codigoLocal={0} //no se va a necesitar porque se va a insertar directamente al server
                  grabarFila={grabarFila}
                  removerFila={removerFila}
                  incrementarCodigoLocal={() => {}} //no se va a necesitar porque se va a insertar directamente al server
                  codigoGrid={props.row.Valor} //codigoXCampovalor
                  editable={
                    props.numeroEstadosPosteriores == 0 || !props.permisoEditar
                      ? false
                      : true
                  }
                  inputRef={(el) => (refs.current[index] = el)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
              </Grid>
            </Grid>
          );
        }
        break;

      case 21:
        //Campo Calculado
        component = (
          <div className={props.classes.flexRow}>
            <FunctionsIcon
              style={{ color: "rgb(33, 150, 243)", marginRight: 10 }}
            />
            <TextField
              required={props.row.Requerido}
              id={`txt${props.row.CodigoCampo}`}
              type="number"
              label={props.row.Nombre}
              style={{ width: "100%" }}
              margin="normal"
              variant="outlined"
              inputProps={{ zIndex: 0 }}
              defaultValue={row.Valor.Valor}
              disabled={true}
              inputRef={(el) => (refs.current[index] = el)}
              onKeyDown={(e) => handleKeyDown(e, index)}
            />
          </div>
        );
        break;
      case 22: {
        //Picklist API
        let valorFila = camposPersonalizados.find(
          (f) => f.CodigoCampo === props.row.CodigoCampo
        );

        let itemFila = [];
        if (valorFila && valorFila.Valor) {
          itemFila = valorFila.Valor.Valor;
        } else {
          itemFila = [];
        }

        let itemAutoComplete = null;
        if (itemFila && row.Items) {
          itemAutoComplete = row.Items.find((w) => w.id === itemFila);
        }
        console.log("picklist1-valor");
        console.log(itemAutoComplete);

        component = (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl style={{ width: "100%" }} required={row.Requerido}>
                <Autocomplete
                  name={"select" + row.Nombre}
                  id={"select" + row.Nombre}
                  options={row.Items}
                  getOptionLabel={(option) => option.name}
                  value={
                    row.Valor.Valor && row.Items
                      ? row.Items.find((w) => w.id === row.Valor.Valor)
                      : null
                  }
                  // onChange={this.onSelectedItemsCamposDinamicos(row)}
                  onChange={props.onChangeCampoPersonalizadosAutocomplete(
                    row.CodigoCampo
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={row.Nombre}
                      variant="outlined"
                    />
                  )}
                  inputRef={(el) => (refs.current[index] = el)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
                <FormHelperText />
              </FormControl>
            </Grid>
          </Grid>
        );
        break;
      }
      case 23: {
        let find = camposPersonalizados.find(
          (f) => f.CodigoCampo == props.row.CodigoCampo
        );

        let selectedItems = [];
        if (find && find.Valor) {
          selectedItems = find.Valor;
        } else {
          selectedItems = [];
        }
        let Items = [];
        if (!(props.row.Items === undefined)) Items = props.row.Items;
        component = (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl
                style={{ width: "100%" }}
                required={props.row.Requerido}
              >
                <InputLabel htmlFor="">{props.row.Nombre}</InputLabel>
                <Select
                  // multiple
                  placeholder={
                    props.row.PlaceHolderIgualNombre == true
                      ? props.row.Nombre
                      : props.row.PlaceHolderText
                  }
                  name={"select" + props.row.Nombre}
                  input={
                    <Input
                      name={"select" + props.row.Nombre}
                      id={"select" + props.row.Nombre}
                    />
                  }
                  disabled={!props.permisoEditar ? true : false}
                  defaultValue={row.Valor.Valor}
                  onChange={props.onChangeCampoPersonalizado(row.CodigoCampo)}
                  inputRef={(el) => (refs.current[index] = el)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                >
                  {Items.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText />
              </FormControl>
            </Grid>
          </Grid>
        );
        break;
      }
      case 25:
        //Sketch
        // iterarKey();
        component = (
          <React.Fragment>
            <div className={props.classes.flexRow}>
              <div>
                <strong>{row.Nombre}</strong>
              </div>
              <ReactSketchCanvas
                ref={(el) => (myRefs.current[iKey + row.CodigoCampo] = el)}
                strokeWidth={5}
                strokeColor="black"
              />
              <button
                onClick={() => {
                  console.log("dibujo");
                  myRefs.current[iKey + row.CodigoCampo].clearCanvas();
                }}
              >
                Limpiar
              </button>
              <button
                onClick={() =>
                  uploadSketch(
                    props.codigoTarea,
                    row,
                    props.recargarDatos,
                    iterarKey,
                    iKey + row.CodigoCampo
                  )
                }
              >
                Guardar
              </button>
            </div>
            {props.row.Valor.length > 0 && (
              <div
                className={props.classes.inputField}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  alt="sketch"
                  src={props.row.Valor[props.row.Valor.length - 1].Path}
                  style={{ maxWidth: "100%" }}
                />
              </div>
            )}
          </React.Fragment>
        );
        break;
      default:
        component = (
          <div>
            <span>
              Este campo no esta disponible, por favor comuniquese con un
              administrador.
            </span>
          </div>
        );
        break;
    }
    return component;
  }

  const classes = useStyles();

  return (
    <div>
      <div class={classes.tituloPaper}>
        <div class={classes.gestionGeneral}>
          <Typography variant="h5" gutterBottom>
            Formulario
          </Typography>
        </div>
      </div>
      <Paper className={classes.paper}>
        {camposPersonalizados !== null ? (
          <div className={classes.container}>
            {camposPersonalizados.map((row, i) => (
              <Grid item xs={12} lg={12} sm={12} style={{ marginTop: 35 }}>
                <DibujarCampo
                  row={row}
                  index={i}
                  camposPersonalizados={props.camposPersonalizados}
                  codigoTarea={props.tarea.AvisoTarea.CodigoAvisoTarea}
                  recargarDatos={props.recargar}
                  onPressInactivarCampoValor={props.onPressInactivarCampoValor}
                  quitarDocAdjuntoDeEstado={props.quitarDocAdjuntoDeEstado}
                  permisoEditar={props.permisoEditar}
                  onChangeCampoPersonalizado={props.onChangeCampoPersonalizado}
                  onChangeCampoPersonalizadosAutocomplete={
                    props.onChangeCampoPersonalizadosAutocomplete
                  }
                  classes={classes}
                  CodigoPadre={row.CodigoFormularioCampoValor}
                  TablaPadre={"AITFormularioCampoValor"}
                />
              </Grid>
            ))}
          </div>
        ) : (
          <div>
            <CircularProgress />
          </div>
        )}
      </Paper>
    </div>
  );
}
// }
//const FormularioContainer = withStyles(useStyles)(Formulario);
export default withRouter(TareaFormulario);
